import Link from "next/link";
import styled from "styled-components";

import { S3_BASE_URL } from "../../config/constants";
import { colors } from "../../config/styles";

export function BannerCTAHub({ hub }) {
  return (
    <Link prefetch={false} href={`/${hub.slug}`} passHref>
      <Container
        primaryColor={hub.primaryColor}
        secondaryColor={hub.secondaryColor}
        tertiaryColor={hub.tertiaryColor}
      >
        {!hub.logoSponsorUrl && !!hub.logoHeaderUrl && (
          <img src={hub.logoHeaderUrl} />
        )}

        {!!hub.logoSponsorUrl && !!hub.logoHeaderUrl && (
          <img src={hub.logoSponsorUrl} />
        )}

        <span>
          Confira notícias, jogos e resultados do <strong>{hub.title}</strong>{" "}
          em nosso hub
        </span>
        <div>Clique aqui</div>
      </Container>
    </Link>
  );
}

const Container = styled.a`
  height: 2.5rem;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
    90deg,
    ${({ tertiaryColor }) => tertiaryColor + "E6"} 0%,
    ${({ secondaryColor }) => secondaryColor + "F2"} 100%
  );

  span {
    font-size: 0.75rem;

    color: #ffffff;

    margin: 0 1rem;
  }

  img {
    max-height: 24px;
  }

  div {
    height: 2.5rem;
    padding: 0 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.75rem;
    font-weight: bold;

    background-color: ${({ primaryColor }) => primaryColor + "BF"};
    // color: ${({ primaryColor }) => primaryColor};
    color: ${colors.GRAY_WHITE};

    &:hover {
      background-color: ${({ primaryColor }) => primaryColor + "66"};
    }
  }

  @media screen and (max-width: 768px) {
    span,
    div {
      font-size: 1rem;
    }

    img {
      display: none;
    }
  }
`;
