import axios from 'axios';
import { API_BASE_URL } from '../config/constants';

const serviceUrl = `${API_BASE_URL}/hubs`;
export default class HubsService {
  static getCtasHubs() {
    return axios.get(`${serviceUrl}/ctas`);
  }

  static getBySlug(slug) {
    return axios.get(`${serviceUrl}/slug/${slug}`);
  }
}
